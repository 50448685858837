<div class="filter-values-header">
  <mat-icon class="filter-values-icon" [svgIcon]="typeIcon()" />

  <div class="filter-values-header__content">
    <ng-container
      *ngTemplateOutlet="headerTemplate(); context: { $implicit: values().length, count: values().length }"
    />
  </div>
</div>

<div class="filter-values-items">
  @for (item of values(); track item.key; let index = $index) {
    <div
      class="filter-values-items__item"
      [class.filter-values-items__item--not-found]="!item.value && !item.isLoading"
      [attr.title]="item.value || item.isLoading ? null : notFoundText()"
    >
      @if (!item.isLoading) {
        <mat-icon class="filter-values-icon" [svgIcon]="item.value ? typeIcon() : 'mdi:alert-outline'" />
      } @else {
        <mpcm-article-ranges-inline-spinner />
      }

      <div class="filter-item-content">
        @if (item.value) {
          <ng-container
            *ngTemplateOutlet="
              itemTemplate();
              context: { $implicit: item.value, index: index, key: item.key, value: item.value }
            "
          />
        } @else {
          <span class="filter-item-content__key">{{ item.key }}</span>
        }
      </div>

      <mat-icon
        class="filter-values-icon filter-values-remove-icon"
        svgIcon="mdi:close"
        (click)="onRemoveClick(item.key)"
      />
    </div>
  }
</div>
