@if (categoriesWithProperties().length) {
  @for (category of categoriesWithProperties(); track $index) {
    <mpcm-category-card
      class="category-card"
      [category]="category"
      [categoryType]="categoryTypeDescriptions[category.type]"
      [expansionStateKeyPrefix]="CATEGORY_PROPERTIES_EXPANSION_STATE_KEY"
      [basePath]="basePath()"
      [articleAttributes]="articleAttributes()"
    />
  }
} @else {
  <mat-card>
    <span class="no-result"> Keine Warengruppen vorhanden. </span>
  </mat-card>
}

@if (metadataPropertiesKeyValuesList().length) {
  <mpcm-key-value-panel
    headerName="Allgemeine Eigenschaften"
    panelLabel="Alle allgemeinen Eigenschaften"
    [keyValuesList]="metadataPropertiesKeyValuesList()"
    [expansionStateKey]="METADATA_PROPERTIES_EXPANSION_STATE_KEY"
    [isPanelHighlighted]="featuresHighlighted()"
  />
} @else {
  <mat-card>
    <span class="no-result"> Keine allgemeinen Eigenschaften vorhanden. </span>
  </mat-card>
}

@if (tagsKeyValuesList().length) {
  <mpcm-key-value-panel
    headerName="Kennzeichen"
    panelLabel="Kennzeichen"
    [keyValuesList]="tagsKeyValuesList()"
    [expansionStateKey]="TAGS_EXPANSION_STATE_KEY"
    [isPanelHighlighted]="tagsHighlighted()"
  />
} @else {
  <mat-card>
    <span class="no-result"> Keine Kennzeichen vorhanden. </span>
  </mat-card>
}
