@if (iconName(); as iconName) {
  <div class="icon-background">
    <mat-icon class="material-icons-outlined" [mpIcon]="iconName" />
  </div>
}
<div class="data">
  <mpcm-packungsinfo-label [label]="entryLabel()" />
  <div class="values-row">
    <ng-content />
  </div>
</div>
