<mpcm-packungsinfo-label label="Zusätzliche Hinweise" />
<mat-chip-list aria-label="Zusätzliche Hinweise">
  @if (isConsumptionUnit()) {
    <mpcm-packungsinfo-tag iconName="person" label="Verbrauchseinheit" />
  }
  @if (isDispatchUnit()) {
    <mpcm-packungsinfo-tag iconName="local_shipping" label="Lieferbar" />
  }
  @if (isInvoiceUnit()) {
    <mpcm-packungsinfo-tag iconName="inventory" label="Fakturiereinheit" />
  }
  @if (isVariableUnit()) {
    <mpcm-packungsinfo-tag iconName="view_agenda" label="Variable Einheit" />
  }
</mat-chip-list>
