<mat-form-field class="search-field-form-field" appearance="fill">
  <ng-container matPrefix>
    @if (isSearchInProgress()) {
      <mpcm-article-ranges-inline-spinner class="search-field-form-field__spinner" />
    } @else {
      <mat-icon class="search-field-form-field__icon" svgIcon="mdi:magnify" />
    }
  </ng-container>

  <input
    #input
    matInput
    type="text"
    [value]="fieldControl.value"
    [placeholder]="label() ?? ''"
    [disabled]="disabled()"
    (keyup.enter)="onKeyupEnter()"
    [matAutocomplete]="autocompletePanel!.matAutocomplete"
    [matAutocompleteDisabled]="!autocompletePanel"
    (input)="fieldControl.setValue(input.value)"
  />

  <mat-icon
    *ngrxLet="getValidationErrorMessage() as validationErrorMessage"
    class="search-field-form-field__icon"
    [ngClass]="{ 'search-field-form-field__icon--hidden': !validationErrorMessage }"
    matSuffix
    svgIcon="mdi:alert-circle"
    color="warn"
    [title]="validationErrorMessage"
  />
</mat-form-field>

<mp-autocomplete-panel
  #autocompletePanel
  [options]="options()"
  [optionTemplate]="optionTemplate.template"
  (valueSelected)="onSearchItemSelected($event)"
>
  <ng-template
    #optionTemplate="mpTypedTemplate"
    mpTypedTemplate
    [contextType]="autocompletePanel.optionTemplateContextType"
    let-option
  >
    <mpcm-search-field-autocomplete-option [option]="option" />
  </ng-template>
</mp-autocomplete-panel>
