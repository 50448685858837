<h3 class="mat-h3">{{ categoryType() }} Eigenschaften</h3>

<mat-card class="card--no-bot-radius">
  <div class="categories-card" [ngClass]="{ 'categories-card--highlighted': isWholeCategoryHighlighted() }">
    <div class="categories-header">
      <mat-icon class="warengruppen-path__type-icon material-icons-outlined">category</mat-icon>
      <span class="warengruppen-path__type">{{ categoryType() }}</span>
      @if (category().version) {
        <span class="warengruppen-path__type-version"> {{ category().version }}</span>
      }
    </div>

    @if (category().category) {
      <div class="breadcrumb-items">
        <mpcm-breadcrumb-item
          [categoriesPath]="category().category"
          [showParents]="true"
          [showAllCategoryCodes]="true"
          [showIcons]="true"
          [showCopyCodeButtons]="true"
          [basePath]="basePath()"
        />
      </div>
    }
    @if (isWholeCategoryHighlighted()) {
      <mpcm-global-article-icon />
    }
  </div>
</mat-card>

@if (categoryPropertiesKeyValuesList().length > 0) {
  <mpcm-key-value-panel
    [panelLabel]="'Alle ' + categoryType() + ' Eigenschaften'"
    [keyValuesList]="categoryPropertiesKeyValuesList()"
    [expansionStateKey]="expansionStateKey()"
    [isPanelHighlighted]="areCategoryPropertiesHighlighted()"
  />
} @else {
  <mat-card>
    <span class="no-result"> Keine {{ categoryType() }} Eigenschaften vorhanden. </span>
  </mat-card>
}
