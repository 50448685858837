import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

@Component({
  selector: 'mpcm-article-ranges-inline-spinner',
  standalone: true,
  templateUrl: './article-ranges-inline-spinner.component.html',
  styleUrl: './article-ranges-inline-spinner.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatLegacyProgressSpinnerModule],
})
export class ArticleRangesInlineSpinnerComponent {
  @HostBinding('class') protected readonly class = 'mpcm-article-ranges-inline-spinner';
}
