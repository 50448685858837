<span class="pair" [title]="formattedLabelTitle() + formattedValue()">
  @if (label(); as label) {
    <span class="pair__label">{{ label }}:</span>
  }
  <span class="pair__value" [class]="'pair__value--' + valueSize()">
    {{ formattedValue() }}
  </span>
  @if (additionalInfo(); as additionalInfo) {
    <span class="pair__additional-info" [class]="'pair__additional-info--' + valueSize()">
      {{ additionalInfo }}
    </span>
  }
</span>
