@if (headerName()) {
  <h3 class="header">{{ headerName() }}</h3>
}

<ng-template #panelHeaderTemplate>
  <div class="key-value-panel-header">
    <span class="key-value-panel-header__name">Name</span>
    <span class="key-value-panel-header__value">Wert</span>
  </div>
</ng-template>

<mp-expandable-panel
  class="mp-expandable-panel--no-padding mp-expandable-panel--stacked"
  [(expanded)]="isPanelOpened"
  [expandButtonLabel]="'test'"
  [panelHeaderTemplate]="panelHeaderTemplate"
  [expandButtonLabel]="panelLabel()"
>
  <ng-template>
    <div class="key-value-panel-content" [ngClass]="{ 'key-value-panel-content--highlighted': isPanelHighlighted() }">
      @for (keyValue of keyValuesList(); track $index) {
        <mpcm-key-value-row
          class="expansion-panel-item"
          [name]="keyValue.key"
          [value]="keyValue.value"
          [additionalValue]="keyValue.additionalValue"
        >
          @if (isPanelHighlighted()) {
            <mpcm-global-article-icon class="expansion-panel-item__global-article-star" additional-content />
          }
        </mpcm-key-value-row>
      }
    </div>
  </ng-template>
</mp-expandable-panel>
