import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { IconDirective } from '@core/shared/util';

import { PackungsinfoLabelComponent } from '../packungsinfo-label/packungsinfo-label.component';

import { PackungsinfoValueLabelPairComponent } from './packungsinfo-value-label-pair/packungsinfo-value-label-pair.component';

@Component({
  selector: 'mpcm-packungsinfo-entry',
  standalone: true,
  templateUrl: './packungsinfo-entry.component.html',
  styleUrl: './packungsinfo-entry.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule, PackungsinfoValueLabelPairComponent, PackungsinfoLabelComponent, IconDirective],
})
export class PackungsinfoEntryComponent {
  readonly entryLabel: InputSignal<string> = input.required<string>();
  readonly iconName: InputSignal<string | undefined> = input<string>();
}
