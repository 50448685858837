import { ChangeDetectionStrategy, Component, InputSignal, Signal, computed, input } from '@angular/core';

import { CopyToClipboardButtonComponent, GlobalArticleIconComponent } from '@core/ui';

import { ArticleAttributes, ArticlePackagingUnit, ArticlePackagingUnitIdentification } from '../../../models';
import { PackungsinfoLabelComponent } from '../packungsinfo-label/packungsinfo-label.component';
import { isPropertyHighlighted } from '../utils/is-propety-highlighted';

@Component({
  selector: 'mpcm-packungsinfo-identifications',
  standalone: true,
  templateUrl: './packungsinfo-identifications.component.html',
  styleUrl: './packungsinfo-identifications.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [PackungsinfoLabelComponent, CopyToClipboardButtonComponent, GlobalArticleIconComponent],
})
export class PackungsinfoIdentificationsComponent {
  readonly packungsinfo: InputSignal<ArticlePackagingUnit> = input.required<ArticlePackagingUnit>();
  readonly articleAttributes: InputSignal<ArticleAttributes | undefined> = input<ArticleAttributes>();

  private readonly _packagingUnitIdKey: Signal<string> = computed(() => {
    return `packagingUnits.${this.packungsinfo().packagingUnitId}`;
  });

  areAllIdentificationsHighlighted(): boolean {
    const key = `${this._packagingUnitIdKey()}.identifications`;
    return isPropertyHighlighted(this.articleAttributes(), key);
  }

  isIdentificationHighlighted(identification: ArticlePackagingUnitIdentification): boolean {
    const key = `${this._packagingUnitIdKey()}.identifications.${identification.identificationId}`;
    return !this.areAllIdentificationsHighlighted() && isPropertyHighlighted(this.articleAttributes(), key);
  }
}
