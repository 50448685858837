import { DecimalPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogModule as MatDialogModule,
} from '@angular/material/legacy-dialog';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { filter } from 'rxjs';

import { ConfirmDialogComponent, ConfirmDialogData, InlineEditComponent } from '@core/ui';

import { FavoritesList, UpdateFavoritesListEvent } from '../../models';

@Component({
  standalone: true,
  selector: 'mpcm-favorites-list-item',
  templateUrl: './favorites-list-item.component.html',
  styleUrls: ['./favorites-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DecimalPipe, MatMenuModule, MatIconModule, MatButtonModule, MatDialogModule, InlineEditComponent],
})
export class FavoritesListItemComponent {
  @HostBinding('class') readonly class = 'mpcm-favorites-list-item';

  @Input() favoritesList!: FavoritesList;

  @HostBinding('class.mpcm-favorites-list-item--active')
  @Input()
  isActive = false;

  @Output() readonly listDelete: EventEmitter<string> = new EventEmitter<string>();

  @Output() readonly listUpdate: EventEmitter<UpdateFavoritesListEvent> = new EventEmitter<UpdateFavoritesListEvent>();

  constructor(
    private readonly dialog: MatDialog,
    private readonly destroyRef: DestroyRef,
  ) {}

  onUpdateClick(listId: string, name: string): void {
    this.listUpdate.emit({ listId, name });
  }

  onDeleteClick(listId: string): void {
    this.dialog
      .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
        data: {
          title: 'Löschen bestätigen',
          message: 'Bist du dir sicher, dass du die Liste löschen willst?',
        },
      })
      .afterClosed()
      .pipe(filter(Boolean), takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => this.listDelete.emit(listId),
      });
  }
}
