import { ChangeDetectionStrategy, Component, InputSignal, Signal, computed, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyChipsModule } from '@angular/material/legacy-chips';

import { ArticlePackagingTag } from '../../../../models';
import { ArticlePackagingUnit } from '../../../models';
import { PackungsinfoLabelComponent } from '../packungsinfo-label/packungsinfo-label.component';
import { PackungsinfoTagComponent } from '../packungsinfo-tag/packungsinfo-tag.component';

@Component({
  selector: 'mpcm-packungsinfo-tags',
  standalone: true,
  templateUrl: './packungsinfo-tags.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatLegacyChipsModule, MatIconModule, PackungsinfoLabelComponent, PackungsinfoTagComponent],
})
export class PackungsinfoTagsComponent {
  readonly packungsinfo: InputSignal<ArticlePackagingUnit> = input.required<ArticlePackagingUnit>();

  protected readonly isBaseUnit: Signal<boolean> = computed<boolean>(() =>
    this.packungsInfoHasTag(ArticlePackagingTag.IsBaseUnit),
  );
  protected readonly isOrderableUnit: Signal<boolean> = computed<boolean>(() =>
    this.packungsInfoHasTag(ArticlePackagingTag.IsOrderableUnit),
  );
  protected readonly isStandardOrderUnit: Signal<boolean> = computed<boolean>(() =>
    this.packungsInfoHasTag(ArticlePackagingTag.IsStandardOrderUnit),
  );

  private packungsInfoHasTag(tag: ArticlePackagingTag): boolean {
    return this.packungsinfo().tags?.some((t) => t === tag) ?? false;
  }
}
