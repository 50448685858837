import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  InputSignal,
  OutputEmitterRef,
  WritableSignal,
  effect,
  input,
  output,
  signal,
  untracked,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { TypedTemplateDirective } from '@core/shared/util';
import {
  ConfirmDialogComponent,
  ConfirmDialogData,
  ConfirmDialogModule,
  SelectComponent,
  SelectOption,
  SelectOptionTemplateContext,
  SelectOptionTemplateRef,
} from '@core/ui';

import { ArticleRangesValueSelectorOptionComponent } from './article-ranges-value-selector-option/article-ranges-value-selector-option.component';

@Component({
  selector: 'mpcm-article-ranges-value-selector',
  standalone: true,
  templateUrl: './article-ranges-value-selector.component.html',
  styleUrl: './article-ranges-value-selector.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    MatIconModule,

    SelectComponent,
    TypedTemplateDirective,
    ArticleRangesValueSelectorOptionComponent,
    ConfirmDialogModule,
  ],
})
export class ArticleRangesValueSelectorComponent<T> {
  readonly value: InputSignal<T> = input.required<T>();

  readonly options: InputSignal<SelectOption<T>[]> = input.required<SelectOption<T>[]>();

  readonly disabled: InputSignal<boolean> = input<boolean>(false);

  readonly requireConfirmation: InputSignal<boolean> = input<boolean>(false);

  readonly optionTemplate: InputSignal<SelectOptionTemplateRef<T> | undefined> = input<SelectOptionTemplateRef<T>>();

  readonly valueChange: OutputEmitterRef<T> = output<T>();

  readonly optionTemplateContextType!: SelectOptionTemplateContext<T>;

  /**
   * Helper internal signal for managing true current value bound to the select control.
   * It is useful for handling temporary state when after the change value also needs to be confirmed.
   * It is a signal instead of a simple variable to make angular also react to programmatic changes.
   */
  protected readonly currentValue: WritableSignal<T | undefined> = signal<T | undefined>(undefined);

  private previousValue: T | undefined = undefined;

  constructor(
    private readonly dialog: MatLegacyDialog,
    private readonly destroyRef: DestroyRef,
  ) {
    effect(() => {
      const currentValue = this.value();
      untracked(() => this.currentValue.set(currentValue));
    });
  }

  protected onSelectionChange(value: T | undefined): void {
    if (!value) {
      return;
    }

    this.previousValue = this.currentValue();
    this.currentValue.set(value);

    this.confirmValueChange();
  }

  private confirmValueChange(): void {
    if (!this.requireConfirmation()) {
      this.emitCurrentValue();
      return;
    }

    this.showConfirmationDialog()
      .pipe(
        tap((isConfirmed) => this.handleConfirmationResult(isConfirmed)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  private showConfirmationDialog(): Observable<boolean> {
    return this.dialog
      .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
        data: {
          title: 'Wechsel entfernt Werte',
          message: 'Der Wechsel des Filter-Typs entfernt alle Werte.',
          trueButtonText: 'Fortfahren',
          falseButtonText: 'Abbrechen',
        },
      })
      .afterClosed()
      .pipe(map((v) => !!v));
  }

  private handleConfirmationResult(isConfirmed: boolean): void {
    if (isConfirmed) {
      this.emitCurrentValue();
      return;
    }

    this.restorePreviousValue();
  }

  private restorePreviousValue(): void {
    if (this.previousValue) {
      this.currentValue.set(this.previousValue);
      this.previousValue = undefined;
    }
  }

  private emitCurrentValue(): void {
    const currentValue: T | undefined = this.currentValue();
    // Current value should always be defined, but the type needs to be narrowed
    if (currentValue !== undefined) {
      this.valueChange.emit(currentValue);
    }
  }
}
