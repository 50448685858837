<mat-card class="packungsinfo">
  <div
    class="packungsinfo__outer-container"
    [class]="{ 'packungsinfo__outer-container--highlighted': isWholePackagingUnitHighlighted() }"
  >
    <div class="packungsinfo__main-container">
      <div class="packungsinfo__main-container-inner">
        <div>
          <span class="packungsinfo__unit-label">
            <mpcm-packungsinfo-label label="Einheit" />
            @if (packungsinfo().packagingStatus; as packagingStatus) {
              <mpcm-article-packaging-status [articlePackagingStatus]="packagingStatus" [isShortTitle]="true" />
            }
          </span>
          <mpcm-packungsinfo-value-label-pair
            [value]="packungsinfo().unit"
            [additionalInfo]="packungsinfo().mappedLongName"
            valueSize="big"
          />
        </div>
        <div>
          <mpcm-packungsinfo-label label="Faktor in BME" />
          <mpcm-packungsinfo-value-label-pair
            [value]="packungsinfo().quantityOfBaseUnit + ' ' + packungsinfo().baseUnit"
            [additionalInfo]="packungsinfo().baseUnitMappedLongName"
            valueSize="medium"
          />
        </div>
        <mpcm-packungsinfo-tags [packungsinfo]="packungsinfo()" />
        <mpcm-packungsinfo-identifications [packungsinfo]="packungsinfo()" [articleAttributes]="articleAttributes()" />
      </div>
      @if (isWholePackagingUnitHighlighted()) {
        <div class="packungsinfo__global-article-icon-col">
          <mpcm-global-article-icon />
        </div>
      }
    </div>
    <mpcm-packungsinfo-expansion-panel
      [packungsinfo]="packungsinfo()"
      [articleAttributes]="articleAttributes()"
      [isExpanded]="showExtendedInfos()"
    />
  </div>
</mat-card>
