import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  InputSignal,
  InputSignalWithTransform,
  OutputEmitterRef,
  OutputRef,
  Signal,
  computed,
  effect,
  input,
  output,
} from '@angular/core';
import { outputFromObservable } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { LetDirective } from '@ngrx/component';
import { Observable, Subject, debounce, interval, race } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { TypedTemplateDirective } from '@core/shared/util';
import { AutocompletePanelComponent, SelectOption } from '@core/ui';

import { ArticleRangesInlineSpinnerComponent } from '../article-ranges-inline-spinner/article-ranges-inline-spinner.component';

import { SearchFieldAutocompleteOptionComponent } from './search-field-autocomplete-option/search-field-autocomplete-option.component';

const MIN_SEARCH_TERM_CHARS = 3;
const SEARCH_DEBOUNCE_TIME = 500;
const DEFAULT_MAX_DISPLAYED_OPTIONS_NUMBER = 50;

@Component({
  selector: 'mpcm-article-ranges-search-field',
  standalone: true,
  templateUrl: './article-ranges-search-field.component.html',
  styleUrl: './article-ranges-search-field.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    LetDirective,
    ReactiveFormsModule,

    MatLegacyFormFieldModule,
    MatIconModule,
    MatLegacyInputModule,
    MatLegacyAutocompleteModule,

    ArticleRangesInlineSpinnerComponent,
    AutocompletePanelComponent,
    TypedTemplateDirective,
    SearchFieldAutocompleteOptionComponent,
  ],
})
export class ArticleRangesSearchFieldComponent<T> {
  @HostBinding('class') protected readonly class = 'mpcm-article-ranges-search-field';

  protected readonly fieldControl: FormControl<string> = new FormControl<string>('', {
    nonNullable: true,
    validators: [Validators.minLength(MIN_SEARCH_TERM_CHARS)],
  });

  private readonly immediateSearch$: Subject<void> = new Subject<void>();

  readonly label: InputSignal<string> = input<string>('');

  readonly disabled: InputSignalWithTransform<boolean, BooleanInput> = input<boolean, BooleanInput>(false, {
    transform: coerceBooleanProperty,
  });

  readonly isSearchInProgress: InputSignal<boolean> = input<boolean>(false);

  readonly autocompleteOptions: InputSignal<SelectOption<T>[]> = input.required<SelectOption<T>[]>();

  readonly maxDisplayedOptions: InputSignal<number> = input<number>(DEFAULT_MAX_DISPLAYED_OPTIONS_NUMBER);

  readonly searchTermChange: OutputRef<string> = outputFromObservable(this.getSearchTermChangeObservable());

  readonly valueSelected: OutputEmitterRef<T> = output<T>();

  protected readonly options: Signal<SelectOption<T>[]> = computed(() =>
    this.autocompleteOptions().slice(0, this.maxDisplayedOptions()),
  );

  constructor() {
    effect(() => {
      if (this.disabled()) {
        this.fieldControl.disable();
      } else {
        this.fieldControl.enable();
      }
    });
  }

  protected onKeyupEnter(): void {
    if (this.fieldControl.valid) {
      this.immediateSearch$.next();
    } else {
      this.fieldControl.markAsPristine();
    }
  }

  protected getValidationErrorMessage(): string | null {
    if (this.fieldControl.pristine && this.fieldControl.hasError('minlength')) {
      return `Der Suchbegriff muss mindestens ${MIN_SEARCH_TERM_CHARS} Zeichen lang sein.`;
    }
    return null;
  }

  protected onSearchItemSelected(selectedItemValue: T): void {
    this.valueSelected.emit(selectedItemValue);

    this.clearSearchField();
  }

  private clearSearchField(): void {
    this.fieldControl.setValue('');
    this.immediateSearch$.next();
  }

  private getSearchTermChangeObservable(): Observable<string> {
    return this.fieldControl.valueChanges.pipe(
      // Normally search term changes are debounced, but if the user presses enter for example, the search should run immediately.
      debounce(() => race(interval(SEARCH_DEBOUNCE_TIME), this.immediateSearch$)),
      map((value) => value.trim()),
      map((value) => (value.length >= MIN_SEARCH_TERM_CHARS ? value : '')),
      distinctUntilChanged(),
    );
  }
}
