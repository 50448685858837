<mat-expansion-panel class="expansion-panel" togglePosition="before" [expanded]="isExpanded()">
  <mat-expansion-panel-header>
    <mat-panel-title> Erweiterte Informationen anzeigen </mat-panel-title>
  </mat-expansion-panel-header>

  @if (showAdditionalTags()) {
    <mpcm-packungsinfo-additional-tags [tags]="packungsinfo().tags!" />
  }

  <div class="expansion-panel__content">
    <div class="expansion-panel__content-item">
      <div>
        <mpcm-packungsinfo-entry
          class="expansion-panel__entry"
          entryLabel="Mehrfachbestellmenge (Losgröße)"
          iconName="fact_check"
        >
          <mpcm-packungsinfo-value-label-pair [value]="packungsinfo().lotSize?.toString() ?? '-'" />
        </mpcm-packungsinfo-entry>
        <mpcm-packungsinfo-entry
          class="expansion-panel__entry"
          entryLabel="Bestellmenge (Bestelleinheit)"
          iconName="production_quantity_limits"
        >
          <mpcm-packungsinfo-value-label-pair [value]="packungsinfo().orderQuantityMin" label="Min" />
          <mpcm-packungsinfo-value-label-pair [value]="packungsinfo().orderQuantityMax" label="Max" />
        </mpcm-packungsinfo-entry>
        <mpcm-packungsinfo-entry class="expansion-panel__entry" entryLabel="Lieferzeit in Tagen" iconName="timer">
          <mpcm-packungsinfo-value-label-pair [value]="packungsinfo().orderLeadTime?.toString() ?? '-'" />
        </mpcm-packungsinfo-entry>
      </div>
    </div>

    <div
      class="expansion-panel__content-item"
      [class]="{ 'expansion-panel__content-item--highlighted': isMeasurementHighlighted() }"
    >
      <div>
        <mpcm-packungsinfo-entry class="expansion-panel__entry" entryLabel="Abmessung (HxBxT)" iconName="straighten">
          <mpcm-packungsinfo-value-label-pair [value]="weightsAndMeasurements().netMeasurement" />
        </mpcm-packungsinfo-entry>
        <mpcm-packungsinfo-entry class="expansion-panel__entry" entryLabel="Verpackung (HxBxT)" iconName="inventory_2">
          <mpcm-packungsinfo-value-label-pair [value]="weightsAndMeasurements().grossMeasurement" />
        </mpcm-packungsinfo-entry>
        <mpcm-packungsinfo-entry class="expansion-panel__entry" entryLabel="Gewicht (Gramm)" iconName="scale">
          <mpcm-packungsinfo-value-label-pair [value]="weightsAndMeasurements().netWeight" label="Netto" />
          <mpcm-packungsinfo-value-label-pair [value]="weightsAndMeasurements().grossWeight" label="Brutto" />
        </mpcm-packungsinfo-entry>
      </div>

      @if (isMeasurementHighlighted()) {
        <mpcm-global-article-icon />
      }
    </div>

    <div class="expansion-panel__content-item">
      <div>
        <mpcm-packungsinfo-entry class="expansion-panel__entry" entryLabel="Luftfeuchtigkeit" iconName="water_drop">
          <mpcm-packungsinfo-value-label-pair [value]="humidity().min" label="Min" />
          <mpcm-packungsinfo-value-label-pair [value]="humidity().max" label="Max" />
        </mpcm-packungsinfo-entry>
        <mpcm-packungsinfo-entry class="expansion-panel__entry" entryLabel="Temperatur" iconName="thermostat">
          <mpcm-packungsinfo-value-label-pair [value]="temperatures().min" label="Min" />
          <mpcm-packungsinfo-value-label-pair [value]="temperatures().max" label="Max" />
        </mpcm-packungsinfo-entry>
        <mpcm-packungsinfo-entry
          class="expansion-panel__entry"
          entryLabel="Maximale Wiederaufbereitung"
          iconName="sync"
        >
          <mpcm-packungsinfo-value-label-pair [value]="packungsinfo().reuseMaximum ?? '-'" />
        </mpcm-packungsinfo-entry>
      </div>
    </div>
  </div>
</mat-expansion-panel>
