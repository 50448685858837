<mp-select
  #selectBox
  class="type-selector"
  [ngClass]="{ 'type-selector--disabled': disabled() }"
  [selectedValue]="currentValue()"
  [options]="options()"
  [disabled]="disabled()"
  [optionTemplate]="optionTemplate() ?? optionDefaultTemplate.template"
  [selectedOptionTemplate]="optionTemplate() ?? optionDefaultTemplate.template"
  (selectedValueChange)="onSelectionChange($event)"
>
  <ng-template
    #optionDefaultTemplate="mpTypedTemplate"
    mpTypedTemplate
    [contextType]="selectBox.optionTemplateContextType"
    let-option
  >
    @if (option; as selectorOption) {
      <mpcm-article-ranges-value-selector-option [option]="selectorOption" />
    }
  </ng-template>
</mp-select>
