import { ChangeDetectionStrategy, Component, InputSignal, OutputEmitterRef, input, output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

import { IconDirective } from '@core/shared/util';

@Component({
  selector: 'mpcm-article-ranges-button',
  standalone: true,
  templateUrl: './article-ranges-button.component.html',
  styleUrl: './article-ranges-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatLegacyButtonModule, MatIconModule, IconDirective],
})
export class ArticleRangesButtonComponent {
  readonly icon: InputSignal<string> = input.required<string>();

  readonly label: InputSignal<string | undefined> = input<string>();

  readonly buttonClick: OutputEmitterRef<void> = output<void>();
}
