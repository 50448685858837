import { ChangeDetectionStrategy, Component, InputSignal, Signal, computed, input } from '@angular/core';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';

import { GlobalArticleIconComponent } from '@core/ui';

import { ArticleAttributes, ArticlePackagingUnit } from '../../models';
import { ArticlePackagingStatusComponent } from '../article-packaging-status/article-packaging-status.component';

import { PackungsinfoValueLabelPairComponent } from './packungsinfo-entry/packungsinfo-value-label-pair/packungsinfo-value-label-pair.component';
import { PackungsinfoExpansionPanelComponent } from './packungsinfo-expansion-panel/packungsinfo-expansion-panel.component';
import { PackungsinfoIdentificationsComponent } from './packungsinfo-identifications/packungsinfo-identifications.component';
import { PackungsinfoLabelComponent } from './packungsinfo-label/packungsinfo-label.component';
import { PackungsinfoTagsComponent } from './packungsinfo-tags/packungsinfo-tags.component';
import { isPropertyHighlighted } from './utils/is-propety-highlighted';

@Component({
  selector: 'mpcm-tab-packungsinfo',
  standalone: true,
  templateUrl: './tab-packungsinfo.component.html',
  styleUrls: ['./tab-packungsinfo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatCardModule,

    ArticlePackagingStatusComponent,
    GlobalArticleIconComponent,
    PackungsinfoExpansionPanelComponent,
    PackungsinfoTagsComponent,
    PackungsinfoIdentificationsComponent,
    PackungsinfoLabelComponent,
    PackungsinfoValueLabelPairComponent,
  ],
})
export class TabPackungsinfoComponent {
  readonly packungsinfo: InputSignal<ArticlePackagingUnit> = input.required<ArticlePackagingUnit>();
  readonly articleAttributes: InputSignal<ArticleAttributes | undefined> = input<ArticleAttributes>();
  readonly showExtendedInfos: InputSignal<boolean> = input<boolean>(false);

  readonly isWholePackagingUnitHighlighted: Signal<boolean> = computed(() => {
    return isPropertyHighlighted(this.articleAttributes(), this._packagingUnitIdKey());
  });

  private readonly _packagingUnitIdKey: Signal<string> = computed(() => {
    return `packagingUnits.${this.packungsinfo().packagingUnitId}`;
  });
}
