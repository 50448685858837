import { formatPercent } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, InputSignal, Signal, computed, input } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';

import { GlobalArticleIconComponent } from '@core/ui';

import { ArticlePackagingMeasurementType, ArticlePackagingTag } from '../../../../models';
import { ArticleAttributes, ArticleMeasurement, ArticlePackagingUnit } from '../../../models';
import { PackungsinfoAdditionalTagsComponent } from '../packungsinfo-additional-tags/packungsinfo-additional-tags.component';
import { PackungsinfoEntryComponent } from '../packungsinfo-entry/packungsinfo-entry.component';
import { PackungsinfoValueLabelPairComponent } from '../packungsinfo-entry/packungsinfo-value-label-pair/packungsinfo-value-label-pair.component';
import { isPropertyHighlighted } from '../utils/is-propety-highlighted';

type WeightsAndMeasurements = {
  netMeasurement: string;
  netWeight: string;
  grossMeasurement: string;
  grossWeight: string;
};

type Temperatures = {
  min?: string;
  max?: string;
};

type Humidity = {
  min?: string;
  max?: string;
};

@Component({
  selector: 'mpcm-packungsinfo-expansion-panel',
  standalone: true,
  templateUrl: './packungsinfo-expansion-panel.component.html',
  styleUrl: './packungsinfo-expansion-panel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatExpansionModule,

    PackungsinfoEntryComponent,
    PackungsinfoValueLabelPairComponent,
    GlobalArticleIconComponent,
    PackungsinfoAdditionalTagsComponent,
  ],
})
export class PackungsinfoExpansionPanelComponent {
  readonly packungsinfo: InputSignal<ArticlePackagingUnit> = input.required<ArticlePackagingUnit>();
  readonly articleAttributes: InputSignal<ArticleAttributes | undefined> = input<ArticleAttributes>();
  readonly isExpanded: InputSignal<boolean> = input<boolean>(false);

  private readonly _additionalTags: ArticlePackagingTag[] = [
    ArticlePackagingTag.IsConsumptionUnit,
    ArticlePackagingTag.IsDispatchUnit,
    ArticlePackagingTag.IsInvoiceUnit,
    ArticlePackagingTag.IsVariableUnit,
  ];

  protected readonly weightsAndMeasurements: Signal<WeightsAndMeasurements> = computed(() =>
    this.buildWeightAndMeasurement(),
  );

  protected readonly temperatures: Signal<Temperatures> = computed(() => {
    const min = this.packungsinfo().temperatureMinimum;
    const max = this.packungsinfo().temperatureMaximum;

    return {
      min: min ? `${min}°C` : '-',
      max: max ? `${max}°C` : '-',
    };
  });

  protected readonly humidity: Signal<Humidity> = computed(() => {
    const min = this.packungsinfo().humidityMinimum;
    const max = this.packungsinfo().humidityMaximum;

    return {
      min: min ? formatPercent(min, 'de-DE') : '-',
      max: max ? formatPercent(max, 'de-DE') : '-',
    };
  });

  protected readonly showAdditionalTags: Signal<boolean> = computed(() => {
    return this.packungsinfo().tags?.some((tag) => this._additionalTags.includes(tag)) ?? false;
  });

  protected readonly isMeasurementHighlighted: Signal<boolean> = computed<boolean>(() => {
    const key = `${this._packagingUnitIdKey()}.measurements`;
    return isPropertyHighlighted(this.articleAttributes(), key);
  });

  @HostBinding('class') readonly class = 'mpcm-packungsinfo-expansion-panel';

  private readonly _packagingUnitIdKey: Signal<string> = computed(() => {
    return `packagingUnits.${this.packungsinfo().packagingUnitId}`;
  });

  private buildWeightAndMeasurement(): WeightsAndMeasurements {
    let returnValue: WeightsAndMeasurements = {
      netMeasurement: '',
      netWeight: '',
      grossMeasurement: '',
      grossWeight: '',
    };

    this.packungsinfo()?.measurements?.forEach((measurement) => {
      if (measurement.type === ArticlePackagingMeasurementType.Article) {
        returnValue = {
          ...returnValue,
          netMeasurement: this.buildMeasurementString(measurement),
          netWeight: this.buildWeightString(measurement),
        };
      } else {
        returnValue = {
          ...returnValue,
          grossMeasurement: this.buildMeasurementString(measurement),
          grossWeight: this.buildWeightString(measurement),
        };
      }
    });

    return returnValue;
  }

  private buildMeasurementString(measurement?: ArticleMeasurement): string {
    let returnString = '';

    if (!!measurement?.height && !!measurement?.width && !!measurement?.depth && !!measurement?.dimensionUOM) {
      returnString =
        this.getLocalizedFormatting(measurement.height) +
        ' x ' +
        this.getLocalizedFormatting(measurement.width) +
        ' x ' +
        this.getLocalizedFormatting(measurement.depth) +
        ' ' +
        measurement.dimensionUOM;
    }

    return returnString;
  }

  private buildWeightString(measurement?: ArticleMeasurement): string {
    let returnString = '';
    if (measurement?.weight && measurement?.weightUOM) {
      returnString = this.getLocalizedFormatting(measurement.weight) + ' ' + measurement.weightUOM;
    }

    return returnString;
  }

  private getLocalizedFormatting(value: number): string {
    return value.toLocaleString('de-DE', {
      maximumFractionDigits: 2,
    });
  }
}
