import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';

@Component({
  selector: 'mpcm-packungsinfo-label',
  standalone: true,
  template: '<span class="label truncate" [title]="label()">{{ label() + suffix() }}</span>',
  styleUrls: ['./packungsinfo-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PackungsinfoLabelComponent {
  readonly label: InputSignal<string> = input.required<string>();
  readonly suffix: InputSignal<string> = input<string>('');
}
