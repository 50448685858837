import { ChangeDetectionStrategy, Component, InputSignal, Signal, computed, input } from '@angular/core';

export type PackungsinfoValueSize = 'big' | 'medium' | 'small';

@Component({
  selector: 'mpcm-packungsinfo-value-label-pair',
  standalone: true,
  templateUrl: './packungsinfo-value-label-pair.component.html',
  styleUrl: './packungsinfo-value-label-pair.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PackungsinfoValueLabelPairComponent {
  readonly label: InputSignal<string | undefined> = input<string>();
  readonly value: InputSignal<string | number | undefined> = input<string | number>();
  readonly valueSize: InputSignal<PackungsinfoValueSize> = input<PackungsinfoValueSize>('small');
  readonly additionalInfo: InputSignal<string | undefined> = input<string>();

  protected readonly formattedLabelTitle: Signal<string> = computed(() => {
    return this.label() ? `${this.label()}: ` : '';
  });

  protected readonly formattedValue: Signal<string> = computed(() => {
    const value = this.value();
    return value ? value.toString() : '-';
  });
}
