<div
  class="packungsinfo-identifications"
  [class]="{ 'packungsinfo-identifications--highlighted': areAllIdentificationsHighlighted() }"
>
  <div class="packungsinfo-identifications__title">
    <mpcm-packungsinfo-label label="Packungsidentifikationen" />
    @if (areAllIdentificationsHighlighted()) {
      <mpcm-global-article-icon />
    }
  </div>
  <span class="packungsinfo-identifications__list">
    @for (identification of packungsinfo().identifications; track $index) {
      <span
        class="packungsinfo-identifications__item item"
        [class]="{
          'item--highlighted': isIdentificationHighlighted(identification)
        }"
      >
        <span class="item__label">{{ identification.type }}</span>
        <span class="item__value truncate">
          {{ identification.value }}
        </span>
        <mp-copy-to-clipboard-button [text]="identification.value" />
        @if (isIdentificationHighlighted(identification)) {
          <mpcm-global-article-icon />
        }
      </span>
    }
  </span>
</div>
