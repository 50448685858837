import { ChangeDetectionStrategy, Component, InputSignal, Signal, computed, input } from '@angular/core';
import { MatLegacyChipsModule } from '@angular/material/legacy-chips';

import { ArticlePackagingTag } from '../../../../models';
import { PackungsinfoLabelComponent } from '../packungsinfo-label/packungsinfo-label.component';
import { PackungsinfoTagComponent } from '../packungsinfo-tag/packungsinfo-tag.component';

@Component({
  selector: 'mpcm-packungsinfo-additional-tags',
  standalone: true,
  templateUrl: './packungsinfo-additional-tags.component.html',
  styleUrl: './packungsinfo-additional-tags.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatLegacyChipsModule, PackungsinfoLabelComponent, PackungsinfoTagComponent],
})
export class PackungsinfoAdditionalTagsComponent {
  readonly tags: InputSignal<ArticlePackagingTag[]> = input.required<ArticlePackagingTag[]>();

  protected readonly isConsumptionUnit: Signal<boolean> = computed<boolean>(() =>
    this.packungsInfoHasTag(ArticlePackagingTag.IsConsumptionUnit),
  );
  protected readonly isDispatchUnit: Signal<boolean> = computed<boolean>(() =>
    this.packungsInfoHasTag(ArticlePackagingTag.IsDispatchUnit),
  );
  protected readonly isInvoiceUnit: Signal<boolean> = computed<boolean>(() =>
    this.packungsInfoHasTag(ArticlePackagingTag.IsInvoiceUnit),
  );
  protected readonly isVariableUnit: Signal<boolean> = computed<boolean>(() =>
    this.packungsInfoHasTag(ArticlePackagingTag.IsVariableUnit),
  );

  private packungsInfoHasTag(tag: ArticlePackagingTag): boolean {
    return this.tags().some((t) => t === tag) ?? false;
  }
}
