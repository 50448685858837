import { Observable, of } from 'rxjs';
import { startWith, switchMap, tap } from 'rxjs/operators';

/**
 * Describes a cache value.
 */
export interface CacheValue<TValue, TKey = string> {
  /**
   * The key of the value.
   */
  key: TKey;
  /**
   * The value or `null` if it is loading or not available.
   */
  value: TValue | null;
  /**
   * `true` if the value is currently loading.
   */
  isLoading: boolean;
}

/**
 * Base class for a value cache for the filters.
 */
export abstract class ValueCache<TValue, TKey extends string | number | symbol = string> {
  /**
   * The cached values. Values are cached once they are loaded/searched to minimize load requests.
   */
  private readonly _cache: Map<TKey, CacheValue<TValue, TKey>> = new Map<TKey, CacheValue<TValue, TKey>>();

  /**
   * Returns the unique key of a value.
   */
  protected abstract getKey(value: TValue): TKey;

  /**
   * Loads values by a set of keys, e.g. from a filter.
   */
  protected abstract getValuesByKey(keys: readonly TKey[]): Observable<readonly TValue[]>;

  /**
   * Loads values by a set of keys, e.g. from a filter.
   */
  public loadValues(keys: readonly TKey[]): Observable<readonly CacheValue<TValue, TKey>[]> {
    const missingKeysSet: Set<TKey> = new Set<TKey>();

    for (const key of keys) {
      if (!this._cache.has(key)) {
        // Idea was to set them to in-cache already so that the items
        // will not be loaded twice. But this led to forever loading items
        // if a request was aborted by the browser.
        // TODO: How to recognize that the request was aborted? Didn't get error/complete...
        //  ans: Abort results only in unsubscribing from the observable hence no callback is called
        //       (actually unsubscribing is done by the switchMap operator and abort is a side effect of that).
        //       The only easy way to get notified in that situation is to use for example the 'finalize' operator
        // this._cache.set(key, {
        //   key,
        //   value: null,
        //   isLoading: true,
        // });

        missingKeysSet.add(key);
      }
    }

    if (missingKeysSet.size > 0) {
      const missingKeys: TKey[] = [...missingKeysSet];

      return this.getValuesByKey(missingKeys).pipe(
        tap((values) => this.cacheValuesInternal(values, missingKeys)),
        startWith([]), // start with isLoading:true values while loading
        switchMap(() => of(this.getCachedValues(keys))),
      );
    }

    return of(this.getCachedValues(keys));
  }

  public cacheValues(values: TValue[]): void {
    this.cacheValuesInternal(values);
  }

  protected getCachedValues(keys: readonly TKey[]): readonly CacheValue<TValue, TKey>[] {
    return keys.map((key) => this._cache.get(key) ?? { key, value: null, isLoading: true });
  }

  private cacheValuesInternal(values: Iterable<TValue>, expectedKeys?: readonly TKey[]): void {
    const missingKeysSet: Set<TKey> | undefined = expectedKeys ? new Set<TKey>(expectedKeys) : undefined;

    for (const value of values) {
      const key = this.getKey(value);
      this._cache.set(key, { key, value, isLoading: false });

      missingKeysSet?.delete(key);
    }

    if (missingKeysSet) {
      for (const key of missingKeysSet) {
        this._cache.set(key, { key, value: null, isLoading: false });
      }
    }
  }
}
