<div class="breadcrumb" [class]="{ 'breadcrumb--highlighted': isHighlighted() }">
  <div>
    <div class="breadcrumb__type-item">
      <mat-icon class="breadcrumb__type-icon material-icons-outlined">category</mat-icon>
      @if (categoriesPath(); as categoriesPath) {
        <span class="breadcrumb__type-text">
          {{ categoryPathDescription() }}
        </span>
      } @else {
        <span class="breadcrumb__type-text">{{ categoryTypeDescription() }}</span>
      }
    </div>
    @if (categoriesPath(); as categoriesPath) {
      <mpcm-breadcrumb-item
        [categoriesPath]="categoriesPath"
        [showParents]="showParents()"
        [showLastCategoryCode]="showLastCategoryCode()"
        [showAllCategoryCodes]="showAllCategoryCodes()"
        [showIcons]="showIcons()"
        [basePath]="basePath()"
      />
    }
  </div>

  @if (isHighlighted()) {
    <mpcm-global-article-icon />
  }
</div>
