import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyChipsModule } from '@angular/material/legacy-chips';

import { IconDirective } from '@core/shared/util';

@Component({
  selector: 'mpcm-packungsinfo-tag',
  standalone: true,
  template: ` <mat-chip class="tag" disableRipple>
    <mat-icon class="tag__icon material-icons-outlined" [mpIcon]="iconName()" />
    {{ label() }}
  </mat-chip>`,
  styleUrl: './packungsinfo-tag.component.scss',
  imports: [MatLegacyChipsModule, MatIconModule, IconDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PackungsinfoTagComponent {
  readonly iconName: InputSignal<string> = input.required<string>();
  readonly label: InputSignal<string> = input.required<string>();
}
