import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  InputSignal,
  OutputEmitterRef,
  TemplateRef,
  input,
  output,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { TemplateContext } from '@core/shared/util';

import { CacheValue } from '../../util';
import { ArticleRangesInlineSpinnerComponent } from '../article-ranges-inline-spinner/article-ranges-inline-spinner.component';

interface HeaderTemplateContext extends TemplateContext<number> {
  count: number;
}

interface ItemTemplateContext<TValue, TKey> extends TemplateContext<TValue> {
  index: number;
  key: TKey;
  value: TValue;
}

@Component({
  selector: 'mpcm-article-ranges-filter-values',
  standalone: true,
  templateUrl: './article-ranges-filter-values.component.html',
  styleUrl: './article-ranges-filter-values.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.mpcm-article-ranges-filter-values--disabled]': 'isDisabled()',
  },
  imports: [NgTemplateOutlet, MatIconModule, ArticleRangesInlineSpinnerComponent],
})
export class ArticleRangesFilterValuesComponent<TValue, TKey = string> {
  @HostBinding() readonly class = 'mpcm-article-ranges-filter-values';

  readonly values: InputSignal<readonly CacheValue<TValue, TKey>[]> =
    input.required<readonly CacheValue<TValue, TKey>[]>();

  readonly typeIcon: InputSignal<string> = input.required<string>();

  /**
   * The template to use for a header.
   */
  readonly headerTemplate: InputSignal<TemplateRef<HeaderTemplateContext>> =
    input.required<TemplateRef<HeaderTemplateContext>>();

  /**
   * The template to use for an item.
   */
  readonly itemTemplate: InputSignal<TemplateRef<ItemTemplateContext<TValue, TKey>>> =
    input.required<TemplateRef<ItemTemplateContext<TValue, TKey>>>();

  readonly isDisabled: InputSignal<boolean> = input.required<boolean>();

  readonly notFoundText: InputSignal<string | undefined> = input<string>();

  readonly valueRemove: OutputEmitterRef<TKey> = output<TKey>();

  readonly headerTemplateContextType!: HeaderTemplateContext;

  readonly itemTemplateContextType!: ItemTemplateContext<TValue, TKey>;

  protected onRemoveClick(key: TKey): void {
    this.valueRemove.emit(key);
  }
}
