import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';

import { SelectOption } from '@core/ui';

@Component({
  selector: 'mpcm-search-field-autocomplete-option',
  standalone: true,
  templateUrl: './search-field-autocomplete-option.component.html',
  styleUrl: './search-field-autocomplete-option.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFieldAutocompleteOptionComponent {
  readonly option: InputSignal<SelectOption<unknown>> = input.required<SelectOption<unknown>>();
}
