import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { SelectOption } from '@core/ui';

@Component({
  selector: 'mpcm-article-ranges-value-selector-option',
  standalone: true,
  templateUrl: './article-ranges-value-selector-option.component.html',
  styleUrl: './article-ranges-value-selector-option.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule],
})
export class ArticleRangesValueSelectorOptionComponent {
  readonly option: InputSignal<SelectOption<unknown>> = input.required<SelectOption<unknown>>();
}
